@import 'utils/normalize';
@import 'importer';
@import 'typography';
@import 'utils/classes';

// Foundation Compile
@include foundation-xy-grid-classes;
@include foundation-visibility-classes;
@include foundation-flex-classes;

@import 'utils/overrides';

@import 'swiper/swiper.scss';
@import 'integrations/integrations';

html {
  font-size: 20px;
  font-size: clamp(14px, 1.35vw, 20px);
  @media (max-height: 979px) {
    font-size: clamp(12px, 2.1vh, 20px);
  }
}

body {
  &.changing-theme {
    transition: 1s;
    will-change: background-color;
  }
  &.dark-mode {
    background-color: $ar-soft-dark;
  }
  &.dragging {
    cursor: grabbing !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
