* {
  font-family: $body-font;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@include static-theme('*', color, $ar-dark-blue, $ar-dark-white);

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  font-size: 1.2rem;
}

h1,
.h1,
.heading-1,
h2,
.h2,
.heading-2,
h3,
.h3,
.heading-3,
h4,
.h4,
.heading-4,
h5,
.h5,
.sub-heading,
h6,
.h6,
p,
.p,
.paragraph,
small,
.small,
.details {
  display: block;
  margin: 0 0 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

// 40/40
h1,
.h1,
.heading-1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
}
// 30/36
h2,
.h2,
.heading-2 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 700;
}
// 26/40
h3,
.h3,
.heading-3 {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 700;
}

// 22/26
h4,
.h4,
.heading-4 {
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: 700;
}
// 18/22 ?
h5,
.h5,
.sub-heading {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.1rem;
  // TODO: make default
  &.alt {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}
// 16/20 (design: 16/19)
p,
.p,
.paragraph {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
}
// 14/18 (design: 14/17)
small,
.small,
.details {
  font-size: 0.68rem;
  line-height: 0.9rem;
}

// 10 (design: 10/12)
.xs {
  font-size: 0.5rem;
  line-height: 0.6rem;
}

a {
  text-decoration: none;
  &.link {
    line-height: 1em;
    transition: transition(color, 0.35s);
    font-weight: 700;
    color: $ar-blue;
  }
}

@include static-theme-dark-content {
  a.link {
    background-color: $ar-light-blue;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    mix-blend-mode: normal;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.white-space {
  white-space: pre-line;
}

.word-break {
  word-break: break-word;
}

.italic {
  font-style: italic;
}

.text-weight {
  &--lighter {
    font-weight: lighter;
  }
  &--500 {
    font-weight: 500;
  }
  &--600 {
    font-weight: 600;
  }
  &--bold {
    font-weight: bold;
  }
}
