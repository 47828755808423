.margin {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include modifier(xlarge) {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  @include modifier(large) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @include modifier(medium) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @include modifier(small) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  @include modifier(xsmall) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @include modifier(top) {
    margin-bottom: 0;
  }
  @include modifier(bottom) {
    margin-top: 0;
  }
  @include modifier(none) {
    margin: 0;
  }
}

.padding {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include modifier(xlarge) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  @include modifier(large) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include modifier(medium) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @include modifier(small) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  @include modifier(xsmall) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @include modifier(top) {
    padding-bottom: 0;
  }
  @include modifier(bottom) {
    padding-top: 0;
  }
}

.spacer {
  @include modifier(h) {
    display: inline-block;
    width: 0.75rem;
    &.spacer--small {
      width: 0.35rem;
    }
    &.spacer--medium {
      width: 0.5rem;
    }
    &.spacer--midlarge {
      width: 0.8rem;
    }
    &.spacer--large {
      width: 1rem;
    }
    &.spacer--slarge {
      width: 1.2rem;
    }
    &.spacer--mlarge {
      width: 1.5rem;
    }
    &.spacer--xlarge {
      width: 2rem;
    }
    &.spacer--xxlarge {
      width: 3rem;
    }
    &.spacer--huge {
      width: 4rem;
    }
  }
  @include modifier(v) {
    height: 0.75rem;
    &.spacer--small {
      height: 0.35rem;
    }
    &.spacer--msmall {
      height: 0.4rem;
    }
    &.spacer--medium {
      height: 0.5rem;
    }
    &.spacer--midlarge {
      height: 0.75rem;
    }
    &.spacer--large {
      height: 1rem;
    }
    &.spacer--slarge {
      height: 1.2rem;
    }
    &.spacer--mlarge {
      height: 1.5rem;
    }
    &.spacer--xlarge {
      height: 2rem;
    }
    &.spacer--xxlarge {
      height: 3rem;
    }
    &.spacer--huge {
      height: 4rem;
    }
  }
}

.logo {
  width: 100%;
  max-width: 7.5rem;
  @include modifier(small) {
    max-width: 6rem;
  }
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &.active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.scrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $ar-blue;
    border-radius: 1rem;
  }
  @include modifier(h) {
    &::-webkit-scrollbar {
      height: 0.25rem;
    }
  }
  @include modifier(v) {
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
  }
}

.rounded {
  border-radius: 50%;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.w100 {
  width: 100%;
  @include modifier(block) {
    display: block;
  }
}

.h100 {
  height: 100%;
}

.hide-overflow {
  overflow: hidden;
}

.one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.divider {
  height: 0.1rem;
  &--thin {
    height: 1px;
  }
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.restricted {
  opacity: 0.5;
  cursor: not-allowed;

  * {
    pointer-events: none;
  }

  .button.button--disabled {
    opacity: 1 !important;
  }
}

@include static-theme('.divider', background-color, #ededed, rgba(#fff, 0.25));

@include generateFromMap(
  $colors-map,
  background,
  background-color,
  $dark-colors-map
);
.color-gradient {
  @include brand-gradient-text(true);
}
@include generateFromMap($colors-map, color, color, $dark-colors-map);
@include generateFromMap($colors-map, border, border-color, $dark-colors-map);
@include generateFromMap($weight-map, weight, font-weight);
