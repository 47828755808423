@mixin theme-light-content() {
  @content;
}

@mixin theme-dark-content() {
  :host-context(body.dark-mode) & {
    @content;
  }
}

@mixin theme($prop, $colourMode, $darkMode, $timing: 1s) {
  @include theme-light-content {
    #{$prop}: $colourMode;
  }
  @include theme-dark-content {
    #{$prop}: $darkMode !important;
  }
  :host-context(body.changing-theme) & {
    transition: $timing;
    will-change: #{$prop};
  }
}

@mixin theme-dark($prop, $darkMode, $timing: 1s) {
  @include theme-dark-content {
    #{$prop}: $darkMode !important;
  }
  :host-context(body.changing-theme) & {
    transition: $timing;
    will-change: #{$prop};
  }
}

@mixin static-theme-dark-content() {
  body.dark-mode {
    @content;
  }
}

@mixin static-theme($selector, $prop, $colourMode, $darkMode, $timing: 1s) {
  #{$selector} {
    #{$prop}: $colourMode;
  }
  @include static-theme-dark-content {
    #{$selector} {
      #{$prop}: $darkMode !important;
    }
  }
  body.changing-theme {
    #{$selector} {
      transition: $timing;
      will-change: #{$prop};
    }
  }
}

@mixin child($child) {
  &__#{$child} {
    @content;
  }
}

@mixin unmodifier($modifier) {
  &:not(#{&}--#{'' + $modifier}) {
    @content;
  }
}

@mixin modifier($modifier) {
  &#{&}--#{'' + $modifier} {
    @content;
  }
}

@mixin generateFromMap($map, $class, $property, $darkMap: '') {
  @if $darkMap != '' {
    @each $key, $value in $map {
      @include static-theme(
        '.#{$class}-#{$key}',
        $property,
        $value,
        getColor($key, $darkMap)
      );
    }
  } @else {
    @each $key, $value in $map {
      .#{'' + $class}-#{'' + $key} {
        #{$property}: $value;
      }
    }
  }
}

@mixin abs-icon($icon, $width, $height) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  background-image: url($icon);
}

@mixin not-selectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin brand-gradient($light: false, $theme: false) {
  @if $theme {
    @include theme(background, $ar-blue, $ar-transparent-blue);
  } @else {
    @if $light {
      background: $ar-transparent-blue;
    } @else {
      background: $ar-blue;
    }
  }
}

@mixin brand-gradient-text($theme: false) {
  @include theme-light-content() {
    @include brand-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    color: transparent;

    mix-blend-mode: normal;
  }
  @if $theme {
    @include theme-dark-content() {
      background: $ar-light-blue;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      color: transparent;

      mix-blend-mode: normal;
    }
  }
}

@mixin brand-gradient-border($width: 1px) {
  border: $width solid;
  border-image-source: $ar-transparent-blue;
}

@mixin brand-gradient-transparent($theme: false) {
  @if $theme {
    @include theme(background, $ar-transparent-blue, $ar-transparent-blue);
  } @else {
    background: $ar-transparent-blue;
  }
}

@mixin brand-gradient-dull($theme: false) {
  @if $theme {
    @include theme(background, $ar-dull-blue, $ar-dull-blue);
  } @else {
    background: $ar-dull-blue;
  }
}

@mixin transition($prop, $time: 0.35s, $enableBounce: true) {
  transition: transition($prop, $time);
  @if $enableBounce {
    transition-timing-function: $bounce;
  }
}

@mixin success-gradient() {
  background: #409037;
}

@mixin error-gradient() {
  background: #e2144a;
}

@mixin warning-gradient() {
  background: #ff6643;
}
