.grid-container {
  padding: 0;
  max-width: 57rem;
  @include breakpoint(large only) {
    max-width: 51.2rem;
  }
  @include breakpoint(medium only) {
    max-width: 32rem;
  }
  @include breakpoint(small only) {
    max-width: 16rem;
  }
  @include modifier(large) {
    @include breakpoint(large up) {
      max-width: 72rem;
    }
  }
  @include modifier(xsmall) {
    max-width: 36rem;
  }
  @include modifier(small) {
    @include breakpoint(large up) {
      max-width: 36rem;
    }
  }
  @include modifier(header) {
    max-width: 69rem;
  }
  @include modifier(wide) {
    max-width: 69rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

img {
  max-width: 100%;
}

@include static-theme(
  '.leaflet-tooltip',
  background-color,
  #fff,
  $ar-soft-dark
);

@include static-theme('.leaflet-tooltip', border-color, #fff, $ar-soft-dark);

@include static-theme(
  '.leaflet-tooltip-left::before',
  border-left-color,
  #fff,
  $ar-soft-dark
);
@include static-theme(
  '.leaflet-tooltip-top::before',
  border-top-color,
  #fff,
  $ar-soft-dark
);
@include static-theme(
  '.leaflet-tooltip-right::before',
  border-right-color,
  #fff,
  $ar-soft-dark
);
@include static-theme(
  '.leaflet-tooltip-bottom::before',
  border-bottom-color,
  #fff,
  $ar-soft-dark
);

.swiper-slide {
  transition-property: none !important;
}
