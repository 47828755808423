@import 'importer';

/** Modals **/
.uf-6yxcj-modal {
  .uf-6yxcj-modal-inner {
    border-radius: 1rem;
    width: 100%;
    max-width: 36.5rem;
    overflow: hidden;
    .uf-6yxcj-content {
      padding: 1.75rem 4rem;
    }
    .uf-6yxcj-actions {
      padding: 0 4rem 1.75rem;
    }
  }
}

.uf-6yxcj-bubble {
  border-radius: 0.75rem !important;
  border: none !important;
  .pointer::before {
    border: none !important;
  }
  .uf-6yxcj-title {
    padding: 1rem !important;
  }
  .uf-6yxcj-bubble-content {
    padding: 1rem;
  }
  .uf-6yxcj-actions {
    padding: 0 1rem 1rem;
  }
}

.uf-6yxcj-modal,
.uf-6yxcj-bubble {
  .uf-6yxcj-actions {
    .uf-6yxcj-button {
      border: none;
      box-shadow: none;
      outline: none;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      padding: 0.4rem 2.15rem;
      min-height: 2.3rem;
      border-radius: 1.25rem;
      display: inline;
      z-index: 1;
      font-family: $body-font;
      text-align: center;
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 0.9rem;
      &::before {
        content: '';
        z-index: -1;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: transition(opacity, 0.35s);
      }
      &:hover::before {
        opacity: 1;
      }
      &.uf-6yxcj-button-primary {
        color: #fff;
        background: $ar-blue;
        &::before {
          background-color: rgba($black, 0.05);
        }
      }
      &:not(.uf-6yxcj-button-primary) {
        border: 1px solid $ar-blue;
        background: transparent;
        @include theme(color, $ar-blue, $ar-blue);
        &::before {
          background-color: rgba(255, 0, 161, 0.1);
        }
      }
    }
  }
}

@include static-theme(
  '.uf-6yxcj-modal::before',
  background-color,
  rgba($ar-dark-blue, 0.6) !important,
  rgba($ar-dark, 0.6)
);
@include static-theme(
  '.uf-6yxcj-modal > .uf-6yxcj-modal-inner',
  background-color,
  #fff,
  $ar-soft-dark
);

@include static-theme(
  '.uf-6yxcj-bubble',
  background-color,
  #fff,
  $ar-soft-dark
);
@include static-theme(
  '.uf-6yxcj-bubble',
  box-shadow,
  0 0 0.5rem 0 rgba($black, 0.06),
  unset
);
@include static-theme(
  '.uf-6yxcj-bubble .pointer::before',
  background-color,
  #fff,
  $ar-soft-dark
);

@include static-theme('.uf-6yxcj-close-button', filter, unset, invert(1));
