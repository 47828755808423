/**
 * Foundation for Sites by ZURB
 * Version 6.6.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// --- Dependencies ---
@import 'foundation-sites/scss/vendor/normalize.scss';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list';

// --- Settings ---
// import your own `settings` here or
// import and modify the default settings through
@import 'settings';

// --- Components ---
// Utilities
@import 'foundation-sites/scss/util/util';
// Global styles
@import 'foundation-sites/scss/global';

// Grids
@import 'foundation-sites/scss/grid/grid';
@import 'foundation-sites/scss/xy-grid/xy-grid';

// Helpers
@import 'foundation-sites/scss/components/float';
@import 'foundation-sites/scss/components/flex';
@import 'foundation-sites/scss/components/visibility';
@import 'foundation-sites/scss/prototype/prototype';
