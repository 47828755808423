@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-LightItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-Light.woff2') format('woff2'),
    url('./../fonts/silka/Silka-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-Bold.woff2') format('woff2'),
    url('./../fonts/silka/Silka-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-SemiBoldItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-BlackItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-Regular.woff2') format('woff2'),
    url('./../fonts/silka/Silka-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-Medium.woff2') format('woff2'),
    url('./../fonts/silka/Silka-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka Extra';
  src: url('./../fonts/silka/Silka-ExtraLight.woff2') format('woff2'),
    url('./../fonts/silka/Silka-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka Extra';
  src: url('./../fonts/silka/Silka-ExtraLightItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-SemiBold.woff2') format('woff2'),
    url('./../fonts/silka/Silka-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-Black.woff2') format('woff2'),
    url('./../fonts/silka/Silka-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-BoldItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-ThinItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-RegularItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-Thin.woff2') format('woff2'),
    url('./../fonts/silka/Silka-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/silka/Silka-MediumItalic.woff2') format('woff2'),
    url('./../fonts/silka/Silka-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

$ar-violet: #d800da;
$ar-violet-2: #931aff;
$ar-magenta: #fc1aa0;

$ar-dark-blue: #021751;
$ar-light-blue: #84aaff;
$ar-transparent-blue: #ceddff;
$ar-blue: #0855ff;
$ar-dull-blue: #063bb3;

$ar-light-white: rgb(255, 255, 255);
$ar-white: #e2e2f1;
$ar-gray: #e7e7e7;
$ar-light-gray: #f5f5fb;
$ar-dull-gray: #bdbdbd;
$ar-dark-gray: #6f6f6f;
$ar-ultra-dark-gray: #949494;
$ar-border-gray: #ebebeb;

// Tiffany
$ar-success: #409037;
$ar-warning: #ff6643;
$ar-error: #e2144a;
$animation-multiplier: 1;

$body-font: 'Silka', sans-serif;

$colors-map: (
  primary: $ar-dark-blue,
  secondary: $ar-violet,
  accent: $ar-magenta,
  accent-2: $ar-violet-2,
  success: $ar-success,
  warning: $ar-warning,
  error: $ar-error,
  alert: $ar-error,
  gray: $ar-gray,
  light-gray: $ar-light-gray,
  dark-gray: $ar-dark-gray,
  white: $ar-light-white,
  violet: $ar-blue,
);

$weight-map: (
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  inherit: inherit,
);

// DARKMODE Colors
$ar-dark: #121212;
$ar-soft-dark: #242424;
$ar-soft-dark-alt: $ar-soft-dark;
$ar-light-dark: #333;
$ar-dark-white: rgba(#fff, 0.87);

$ar-dark-success: #97bf61;
$ar-dark-warning: #fadd74;
$ar-dark-error: #d7645d;

$dark-colors-map: (
  primary: $ar-dark-blue,
  secondary: $ar-violet,
  accent: $ar-magenta,
  accent-2: $ar-violet-2,
  success: $ar-dark-success,
  warning: $ar-dark-warning,
  error: $ar-dark-error,
  alert: $ar-dark-error,
  gray: $ar-gray,
  light-gray: $ar-dark,
  dark-gray: $ar-light-gray,
  white: $ar-light-white,
  violet: $ar-blue,
);

// UTILS
$bounce: cubic-bezier(0.42, 0, 0.3, 1.49);
